a {
    text-decoration: none;
    color: $primary-color;
    font-family: 'Mulish', sans-serif;

    &:hover {
        color: $focus-color;
    }
}

h1 {
    font-family: 'Mulish', sans-serif;
}
