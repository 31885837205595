.Page {
    @include flex-direction(center, column);
    gap: 30px;
    width: 100%;
    height: 100%;
    color: $secondary-color;

    @include medium {
        gap: 20px;
    }

    @include mobile {
        gap: 10px;
    }

    .backgroundContainer {
        position: relative;
        color: $secondary-color;
        height: 100%;
        width: 100%;
        z-index: 0;

        .frontBackground {
            position: relative;
            left: calc($art-show-width + 40px);
            z-index: 2;

            @include medium {
                left: calc($medium-art-show-width + 30px);
                
            }
        
            @include mobile {
                left: calc($mobile-art-show-width + 20px);
            }
        }

        .backBackground {
            z-index: 1;

            @include medium {
                transform: scale(0.8);
            }
        
            @include mobile {
                transform: scale(0.5);
            }
        }

        .paintings {
            position: relative;
            left: -$art-show-width;
            z-index: 3;
            padding: 40px;
            margin: 40px;
            grid-template-columns: repeat(3, 1fr);
            display: grid;
            gap: 20px;

            @include medium {
                left: -$medium-art-show-width;
                padding: 30px;
                margin: 30px;
                gap: 10px;
            }

            @include mobile {
                left: -$mobile-art-show-width;
                padding: 20px;
                margin: 20px;
                gap: 5px;
            }
            
            .imageContainer {
                width: 100%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .animatedCircle {
                padding: 20px 80px;

                @include medium {
                    padding: 20px 55px;
                }
    
                @include mobile {
                    padding: 20px 25px;
                }

                .box {
                    flex: 1;
                    border-radius: 50%;
                    background: $scroll-main-color;
                    width: 60px;
                    height: 60px;
                }
            }
        }
    }
}