.MobileSidebar.darkMode {
    .container {
        background-color: $primary-background;
        border: $form-border;

        div {
            .linkContainer {
                a {
                    color: $primary-color;

                    &:hover {
                        color: $focus-color;
                    }

                    span {
                        color: $focus-color;
                    }
                }
            }

            .mode {
                button {
                    color: $mode-color;
                    background-color: $standard-background;
                }
            }
        }
    }

}

.MobileSidebar.lightMode {
    .container {
        background: $light-background;
        box-shadow: $primary-shadow;

        div {
            .linkContainer {
                a {
                    color: $light-box-background;

                    &:hover {
                        color: $secondary-color;
                    }

                    span {
                        color: $light-box-background;
                    }
                }
            }

            .mode {
                button {
                    color: $mode-color;
                    background-color: $light-box-background;
                }
            }
        }
    }

}

.MobileSidebar {
    @include flex(center, column);
    display: none;
    z-index: 996;

    @include mobile {
        display: block;
    }

    .container {
        z-index: 997;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        width: 180px;
        height: 70%;
        @include flex(center, column);
        overflow: hidden;
        border-bottom-right-radius: 10px;

        div {
            width: 100%;
            height: 100%;
            @include flex-direction(center, column);
            margin-bottom: 20px;
            overflow: hidden;

            .linkContainer {
                flex: 2;
                text-align: center;
                @include flex-direction(center, column);
        
                a {
                    transition: 200ms all ease-in-out;
                    font-weight: bold;
                    @include flex-direction(center, row);
                    width: 60%;
                    overflow: hidden;
                    margin-left: 30px;

                    div {
                        flex: 2;
                        @include flex-direction(center, row);
                        gap: 4px;
                        height: 45px;

                        div {
                            span {
                                color: $focus-color;
                                font-size: $nav-font-size;
                                position: relative;
                                top: 12px;
                            }

                            .mainIcon {
                                position: relative;
                                left: 22px;
                            }
                        }

                        h4 {
                            flex: 3;
                            font-size: $primary-font-size;
                        }
                    }
                }
            }

            .mode {
                flex: 1;
                display: flex;
                width: 100%;
                height: 100%;
                margin-top: 50px;

                button {
                    width: 80px;
                    height: 80px;
                    @include flex-direction(center, column);
                    border: none;
                    border-radius: 50%;
                    box-shadow: $primary-shadow;
        
                    span {
                        color: $mode-color;
                        font-size: $nav-font-size;
                    }
                }
            }
        }
    }

    .toggleButton {
        z-index: 999;
        position: fixed;
        top: 10px;
        left: 10px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: $primary-color;
        border: none;
        cursor: pointer;
    }
}