.Projects.darkMode {
    background: $standard-background;
    color: $primary-color;

    .componentContainer {
        .projectsContainer {
            background: $hero-background;   

            h3 {
                color: $primary-color;
            }
            
            .project, .futured, .game, .vue {
                a {
                    background-color: $primary-background;

                    h4 {
                        color: $primary-color;
                        background-color: $primary-background;
                    }    
                }
            }
        }
    }
}

.Projects.lightMode {
    background: $primary-color;
    color: $primary-color;

    .componentContainer {
        .projectsContainer {
            background: $light-hero-background; 

            h3 {
                color: $focus-color;
                text-shadow: $image-shadow;
            }
            
            .project, .futured, .game, .vue {
                a {
                    background-color: $light-box-background;
                    border: $futured-border;

                    h4 {
                        
                        color: $primary-color;
                    }
                }
            }
        }
    }
}

.Projects {
    @include flex-direction(space-between, row);
    max-width: 100vw;

    @include mobile {
        @include flex-direction(center, column);
    }

    .componentContainer {
        margin-left: 200px;
        width: calc(100vw - 200px);
        position: relative;
        
        @include mobile {
            margin-left: 0;
            width: 100vw;
        }

        h2 {
            position: fixed;
            top: 20px;
            right: 40px;
            z-index: 999;

            @include mobile {
                font-size: $primary-font-size;
            }
        }

        .moreGameButton {
            @include flex(center, center);
            position: relative;
            bottom: $basic-padding;
            font-size: $nav-font-size;
            gap: 5px;
            font-size: 18px;
            margin-top: 60px;
            @include mobile {
                font-size: 14px;
                margin-top: 40px;
            }
        }

        .projectsContainer {
            @include flex-direction(center, column);
            height: 100vh;
            width: 100%;  
            gap: 5px;

            h3 {
                flex: 1;
                font-size: $nav-font-size;
                margin-top: 60px;

                @include mobile {
                    font-size: $big-font-size;
                    margin: 0;
                    padding-top: 100px;
                }
            }

            .futured {
                grid-template-rows: repeat(2, 1fr);
                grid-template-columns: repeat(3, 1fr);

                @include mobile {
                    grid-template-rows: repeat(3, 1fr);
                    grid-template-columns: repeat(2, 1fr);
                }
            }

            .vue {
                grid-template-rows: repeat(3, 1fr);
                grid-template-columns: repeat(4, 1fr);

                @include mobile {
                    grid-template-rows: repeat(4, 1fr);
                    grid-template-columns: repeat(3, 1fr);
                }
            }

            .game {
                grid-template-rows: repeat(3, 1fr);
                grid-template-columns: repeat(3, 1fr);
            }

            .futured, .game {
                flex: 8;
                display: grid;
                margin-bottom: 80px;
                gap: 20px;
                width: 80%;

                @include medium {
                    gap: 15px;
                    width: 90%;
                }

                @include mobile {
                    gap: 10px;
                    margin-bottom: 20px;
                }

                a {
                    width: 100%;
                    height: 100%;
                    border-radius: 20px;
                    @include flex-direction(center, column);

                    @include medium {
                        border-radius: 10px;
                    }

                    @include mobile {
                        border-radius: 5px;
                    }

                        .imageContainer {
                            flex: 4;
                            width: 100%;
                            height: 100%;
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        h4 {
                            flex: 1;
                            @include flex-direction(center, row);
                            gap: 10px;
                            font-size: $mobile-font-size;

                            @include mobile {
                                font-size: $small-mobile-font-size;
                            }
                        }
                }
            }

            .project, .vue {
                flex: 8;
                display: grid;
                margin-bottom: 0;
                gap: 20px;
                width: 80%;
                grid-template-rows: repeat(3, 1fr);
                grid-template-columns: repeat(5, 1fr);

                @include ipad {
                    grid-template-rows: repeat(4, 1fr);
                    grid-template-columns: repeat(4, 1fr);
                    gap: 15px;
                    width: 90%;
                }

                @include mobile {
                    grid-template-rows: repeat(5, 1fr);
                    grid-template-columns: repeat(3, 1fr);
                    gap: 10px;
                    margin-bottom: 20px;
                }

                a {
                    width: 100%;
                    height: 100%;
                    border-radius: 20px;
                    @include flex-direction(center, column);

                    @include medium {
                        border-radius: 10px;
                    }

                    @include mobile {
                        border-radius: 5px;
                    }

                        .imageContainer {
                            flex: 4;
                            width: 100%;
                            height: 100%;
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        h4 {
                            flex: 1;
                            @include flex-direction(center, row);
                            gap: 10px;
                            font-size: $mobile-font-size;

                            @include mobile {
                                font-size: $small-mobile-font-size;
                            }
                        }
                }
            }
        }
    }
  }