//colors
$primary-color: rgb(231, 226, 233);
$secondary-color: rgb(27, 24, 29);
$focus-color: rgb(72, 212, 191);
$mode-color: rgb(235, 203, 20);
$scroll-main-color: rgb(64, 149, 160);
$hover-color: rgb(20, 88, 44);

//backgrounds
$primary-background: rgb(27, 24, 29);
$standard-background: rgb(32, 36, 41);
$owl-green:rgb(26, 127, 112);
$light-box-background: rgb(48, 101, 90);
$owl-focus-green:rgb(25, 181, 157);
$light-background: linear-gradient(to right, $primary-color 96%, rgb(61, 223, 155) 100%);
$secondary-background: linear-gradient(to right, $standard-background 98%, $focus-color 100%);
$hero-background: radial-gradient($focus-color 0%, $standard-background 59%);
$light-hero-background: radial-gradient(rgb(149, 203, 138) 0%, $light-box-background 59%);

//font-size
$primary-font-size: 13px;
$mobile-font-size: 10px;
$small-mobile-font-size: 8px;
$big-font-size: 16px;
$nav-font-size: 18px;
$box-font-size: 33px;
$moving-text-font-size: 74px;
$large-font-size: 44px;
$mobile-large-font-size: 28px;
$mobile-big-font-size: 11px;

//line-height
$primary-line-height: 20px;
$big-line-height: 26px;
$nav-line-height: 35px;
$box-line-height: 42px;
$large-line-height: 50px;
$mobile-large-line-height: 35px;
$mobile-big-line-height: 16px;

//paddings
$primary-padding: 0 40px;
$basic-padding: 40px;
$mobile-padding: 20px;
$basic-padding-left: 40px 40px 40px 20px;
$basic-padding-right: 40px 0 40px 40px;
$title-padding: 0 20px;
$button-padding: 10px 20px;

//margin
$page-contact-margin: 0 200px 0 400px;
$page-about-margin: 40px;

//borders
$primary-border: 5px solid $secondary-color;
$button-border: 1px solid $secondary-color;
$form-border: 1px solid $primary-color;
$image-border: 10px solid $standard-background;
$futured-border: 2px solid $focus-color;
$border-button: 1px solid $scroll-main-color;
$hover-border-button: 1px solid $hover-color;

//shadows
$primary-shadow: 1px 3px 3px rgba(0, 0, 0, 0.5);
$image-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);

//width
$art-show-width : 800px;
$art-show-height: 600px;
$medium-art-show-width: 600px;
$medium-art-show-height: 500px;
$mobile-art-show-width: 100vw;
$mobile-art-show-height: 350px;