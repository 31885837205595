@import './variables';
@import './mixins';
@import './base';
//home
@import './menu.scss';
@import './home.scss';
@import './sidebar.scss';
@import './mobileSidebar.scss';
@import './hero.scss';
@import './futuredContainer.scss';
@import './navbar.scss';
//projects
@import './projects.scss';
@import './games.scss';
//fineart
@import './fineart.scss';
//about
@import './about.scss';
@import './events.scss';
//contact
@import './contact.scss';
@import './movingText.scss';
//art exhibtion
@import './artExhibtion.scss';
@import './pages.scss';



.App {
    width: 100vw;
    height: 100vh;
}