.About.darkMode {
    background: $standard-background;
    color: $primary-color;
}

.About.lightMode {
    background: $primary-color;
    color: $secondary-color;
}

.About {
    @include flex-direction(space-between, row);
    max-width: 100vw;

    .componentContainer {
        margin-left: 200px;
        width: calc(100vw - 200px);
        position: relative;
        min-height: 100vh;
        @include flex-direction(center, row);
        margin-top: 10px;

        @include mobile {
            margin-left: 0;
            width: 100vw;
            @include flex-direction(center, column);
        }

        h2 {
            position: fixed;
            top: 20px;
            right: 40px;
            z-index: 999;

            @include mobile {
                font-size: $primary-font-size;
            }
        }

        .backgroundCanvas {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1; 
        }

        .Bio {
            z-index: 2;
        }
   
        .bioContainer {
            margin-top: $page-about-margin;
            @include flex-direction(center, column);
            gap: 40px;
            max-width: 800px; 
            padding-top: 40px;
            border-radius: 8px;

            .imageContainer {
                overflow: hidden;
                width: 80%;
                height: 300px;

                @include mobile {
                    width: 300px;
                    margin-top: 40px;
                }
    
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 8px;
                    box-shadow: $image-shadow;
                }
            }

            .bioDescription {
                width: 80%;
                @include flex-direction(center, column);
                gap: 20px;
                text-align: justify;
                line-height: $big-line-height;

                @include mobile {
                    font-size: $mobile-font-size;
                    line-height: $primary-line-height;
                    width: 80%;
                }
            }

            button {
                background-color: $primary-background;
                color: $primary-color;
                box-shadow: $primary-shadow;
                transition: 200ms all ease-in-out;
                padding: $button-padding;
                border-radius: 5px;
                border: $button-border;
                margin-bottom: 20px;
                width: 80%;
                letter-spacing: 1px;

                @include mobile {
                    margin-bottom: 0;
                }

                &:hover {
                    color: $focus-color;
                    box-shadow: none;
                }
            }
        }  

        .Bio.darkMode{
            background-color: rgba($primary-background, 0.94);
        }

        .Bio.lightMode{
            background-color: rgba($owl-green, 0.97);
            color: $primary-color;
        }
    }
}