.Navbar.lightMode {
    background-color: transparent;

    h1 {
        font-weight: 600;
        strong {
            &:hover {
                color: $primary-color;
            }
        }
    }

    a {
        &:hover {
            color: $secondary-color;
        }
    }
}

.Navbar {
    @include flex-direction(space-between, row);
    padding: $primary-padding;
    position: fixed;
    top: 40px;
    width: calc(100vw - 200px);

    @include medium {
        top: 30px;
    }

    @include mobile {
        width: 100vw;
        @include flex-direction(center, column);
        top: 60px;
    }

    h1 {
        flex: 1;
        font-weight: 100;
        font-size: $nav-font-size;

        @include medium {
            font-size: $big-font-size;
        }

        @include mobile {
            text-align: center;
        }
        
        strong {
            transition: 200ms all ease-in-out;

            &:hover {
                color: $focus-color;
            }
        }
    }

    .socialLinks {
        flex: 1;
        @include flex(center, flex-end);
        gap: 10px;
        font-size: $nav-font-size; 

        @include medium {
            font-size: $big-font-size;
        }

        @include mobile {
            flex: 4;
            @include flex(center, center);
            padding-top: 20px;
        }
    }
}