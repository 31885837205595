.Games.darkMode {
    background: $standard-background;
    color: $primary-color;

    .componentContainer {
        .projectsContainer {
            background: $hero-background;   
            
            .game {
                a {
                    background-color: $primary-background;

                    h4 {
                        color: $primary-color;
                        background-color: $primary-background;
                    }    
                }
            }
        }
    }
}

.Games.lightMode {
    background: $primary-color;
    color: $secondary-color;

    .componentContainer {
        .projectsContainer {
            background: $light-hero-background; 
            
            .game {
                a {
                    background-color: $primary-color;

                    h4 {
                        color: $secondary-color;
                        background-color: $primary-color;
                    }
                }
            }
        }
    }
}

.Games {
    @include flex-direction(space-between, row);
    max-width: 100vw;

    .componentContainer {
        margin-left: 200px;
        width: calc(100vw - 200px);
        position: relative;

        @include mobile {
            margin-left: 0;
            width: 100vw;
        }

        h2 {
            position: fixed;
            top: 20px;
            right: 40px;
            z-index: 999;

            @include mobile {
                font-size: $primary-font-size;
            }
        }

        .projectsContainer {
            @include flex(center, center);
            width: 100%;
      
            .game { 
                margin-top: 100px;
                grid-template-columns: repeat(3, 1fr);
                display: grid;
                margin-bottom: 80px;
                gap: 20px;
                width: 80%;
                height: 80%;

                @include mobile {
                    grid-template-columns: repeat(2, 1fr);
                    width: 90%;
                    height: 85%;
                }

                a {
                    width: 100%;
                    height: 100%;
                    border-radius: 20px;
                    @include flex-direction(center, column);

                    @include mobile {
                        border-radius: 5px;
                    }

                        .imageContainer {
                            flex: 4;
                            width: 100%;
                            height: 100%;
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        h4 {
                            flex: 1;
                            @include flex-direction(center, row);
                            gap: 10px;

                            @include mobile {
                                font-size: $mobile-font-size;
                            }
                        }
                }
            }
        }
    }
}