@mixin flex($align, $jc) {
    display: flex;
    align-items: $align;
    justify-content: $jc;
}

@mixin flex-direction($jc, $fd) {
    display: flex;
    align-items: center;
    justify-content: $jc;
    flex-direction: $fd;
}

@mixin mobile {
    @media (max-width: 820px) {
        @content
    }
};

@mixin ipad {
    @media (max-width: 1500px) {
        @content
    }
};

@mixin pad {
    @media (max-height: 600px) {
        @content
    }
};

@mixin medium {
    @media (max-width: 1200px) {
        @content
    }
};