.Home.lightMode {
    background-color: $light-box-background;
    color: $focus-color;
}

.Home.darkMode {
    background-color: $standard-background;
    color: $primary-color;
}

.Home {
    @include flex-direction(center, row);
    height: 100%;
    margin-left: 200px;
    width: calc(100vw - 200px);

    @include mobile {
        width: 100%;
        margin: 0
    }
    

    .mainContainer {
        @include flex-direction(center, column);
        width: 100%;
        height: 100%;
    }
}