.slidingTextContainer {
    position: absolute;
    font-size: $moving-text-font-size;
    top: 80px;
    white-space: nowrap;
    color: $focus-color;
    width: 100%;
    font-weight: bold; 
    opacity: 0.2;

    @include mobile {  
        display: none;
    }
}
