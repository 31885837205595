.Sidebar.darkMode {
    background: $secondary-background;
    color: $primary-color;

    .box {
        a {
            color: $primary-color;
            .container {
                background-color: $primary-background;
            }

            &:hover {
                color: $focus-color;
            }
        }
    }

   .mode {
        .container {
            background-color: $primary-background;
            color: $mode-color;
        }
    }
}

.Sidebar.lightMode {
    background: $focus-color;
    color: $secondary-color;

    .titleContainer {
        h2 {
            color: $secondary-color;
            
            &:hover {
                color: $light-box-background;
            }

            span {
                color: $secondary-color;
            }
        }
    }

    .linkContainer {
        a {
            color: $secondary-color;

            span {
                color: $secondary-color;

                &:hover {
                    color: $light-box-background;
                }
            }

            &:hover {
                color: $light-box-background;
            }
        }
    }

    .box {
        &:hover {
            opacity: 0.8;
        }

        .container {
            background-color: $light-box-background;
            color: $primary-color;
        }
    }

    .mode {
        &:hover {
            opacity: 0.9;
        }

        .container {
            background-color: $light-box-background;
        }
    }
}


.Sidebar {
    height: 100%;
    width: 200px;
    @include flex-direction(flex-start, column);
    z-index: 998;
    letter-spacing: 1px;

    @include mobile {
        display: none;
    }

    .titleContainer {
        flex: 1;
        width: 100%;
        @include flex-direction(center, column);
        text-align: center;

        a {
            overflow: hidden;
            
            .imageContainer{
                width: 50%;
                margin: auto;

                @include pad {
                    width: 40%;
                }
    
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    border-radius: 50%;
                }
            }
    
            h2 {
                font-size: $primary-font-size;
                font-family: 'Mulish', sans-serif;
                overflow: hidden;

                @include pad {
                    font-size: $mobile-font-size;
                }
    
                span {
                    font-weight: bold;
                    color: $focus-color;
                    font-size: $big-font-size;

                    @include pad {
                        font-size: $primary-font-size;
                    }
                }
             }
        }

       
    }

    .linkContainer {
        flex: 2;
        @include flex-direction(center, column);
        gap: 18px;
        width: 100%;
        align-items: self-start;
        padding-left: 30px;

        @include pad {
            gap: 12px;
            font-size: $mobile-font-size;
            padding-left: 54px;
        }

        @include medium {
            font-size: $primary-font-size;
        }

        a {
            transition: 200ms all ease-in-out;
            font-weight: bold;
            @include flex-direction(center, row);
            gap: 10px;
            position: relative;
            overflow: hidden;

            @include pad {
                gap: 8px;
            }

            &:hover {
                color: $focus-color;
            }

            div {
                @include flex-direction(center, row);
                gap: 18px;

                @include pad {
                    gap: 16px;
                }
                span {
                    color: $focus-color;
                    font-size: $nav-font-size;
                }
    
                .mainIcon {
                    position: relative;
                    left: 35px;
                }
            }
        }
    }

    .box, .mode {
        flex: 1;
        @include flex-direction(center, column);

        .container {
            border-radius: 10px;
            width: 90px;
            height: 90px;
            @include flex(center, center);
            text-align: center;
            box-shadow: $primary-shadow;
            transition: 200ms all ease-in-out;

            @include pad {
                width: 70px;
                height: 70px;
                font-size: $mobile-font-size;
            }

            &:hover {
                box-shadow: none;
            }
        }
    }

    .mode {
        .container {
            @include flex-direction(center, column);
            gap: 10px;
            border: none;
            color: $mode-color;
            letter-spacing: 1px;

            span {
                color: $mode-color;
                font-size: $nav-font-size;
            }
        }
    }

    .box {
        font-weight: bold;
    }
}



