.FuturedComponents.darkMode {
    .boxContainer {
        background-color: $primary-background;
        color: $primary-color;

        &:hover {
            span {
                color: $primary-color;
            } 
        }

        span {
            color: $focus-color;
        }
    }
}

.FuturedComponents.lightMode {
        .boxContainer {
            background-color: $primary-color;
            transition: 200ms all ease-in-out;
            color: $light-box-background;

            &:hover {
                background-color: $light-box-background;
                color: $focus-color;
            }
            span {
                font-weight: 600;
                color: $secondary-color;
            }
        }
}


.FuturedComponents {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 100%;
    height: 100%;
    overflow: hidden;

    @include medium {
        gap: 15px;
    }

    @include mobile {
        grid-template-rows: repeat(4, 1fr);
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
    }
 
    .boxContainer {
        background-color: $primary-background;
        border-radius: 10px;
        @include flex-direction(center, column);
        text-align: center;
        box-shadow: $primary-shadow;
        transition: 200ms all ease-in-out;
        border: $futured-border;

        @include mobile {
            border-radius: 5px;
        }

        &:hover {
            color: $focus-color;
        }

        div {
            @include flex-direction(center, row);
        }

        h2 {
            font-size: $box-font-size;

            @include medium {
                font-size: $nav-font-size;
            }

            @include mobile {
                font-size: $primary-font-size;
            }
        }

        @include ipad {
            span {
                padding: 0 10px;
            }
        }

        @include mobile {
            span {
                font-size: $mobile-font-size;
            }
        }

        &:hover {
            box-shadow: none;
        }
    }
}