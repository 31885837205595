.Contact.darkMode {
    background: $standard-background;
    color: $primary-color;

    .formContainer {
        form {
            input, textarea {
                border: $form-border;
                color: $primary-color;
            }

            input::placeholder, textarea::placeholder {
                color: $primary-color
            }
        }
    }
}

.Contact.lightMode {
    background: $primary-color;
    color: $secondary-color;

    .formContainer {
        form {
            input, textarea {
                border: $button-border;
                color: $secondary-color;
            }

            input::placeholder, textarea::placeholder {
                color: $secondary-color
            }
        }
    }
}

.Contact {
    @include flex-direction(space-between, row);
    max-width: 100vw;

    .componentContainer {
        margin: $page-contact-margin;
        width: calc(100vw - 200px);
        position: relative;
        height: 100vh;
        @include flex(center, center);
        overflow: hidden;
        z-index: 1;

        @include mobile {
            width: 100vw;
            @include flex-direction(center, column);
            margin: 0 auto;
            margin-top: 100px;
        }
    
        .textContainer {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 40px;
            overflow: hidden;

            @include mobile {
                gap: 10px;
                width: 100vw;
                text-align: center;
            }
    
            h1 {
                font-size: $box-font-size;
                line-height: $box-line-height;

                @include mobile {
                    font-size: $big-font-size;
                    line-height: $big-line-height;
                    padding: 20px;
                }
            }

            .item {
                h2 {
                    font-size: $nav-font-size;
                    line-height: $nav-line-height;

                    @include mobile {
                        font-size: $mobile-font-size;
                        line-height: $primary-line-height;
                    }
                }
        
                span {
                    font-weight: 300;
                    font-size: $primary-font-size;
                    line-height: $primary-line-height;
                }
                
            }
        }
    
        .formContainer {
            flex: 2;
            position: relative;
            overflow: hidden;

            @include mobile {
                width: 80%;
            }
    
            .phoneSvg {
                margin: 50px 100px;
                position: absolute;
                z-index: -1;

                @include mobile {
                    width: 100%;
                    text-align: center;
                    margin: 0 auto;
                }

                 svg {
                    z-index: -1;

                    @include mobile {  
                        width: 50%;
                        bottom: 80px;
                        position: relative;
                    }
                 }
            }
    
            form {
                display: flex;
                flex-direction: column;
                gap: 10px;
                z-index: 900;
                margin-top: 40px;

                @include mobile {
                    margin-top: 0;
                    gap: 5px;
                }
    
                input, textarea {
                    padding: 20px;
                    background-color: transparent;
                    border-radius: 5px;
                    font-size: $primary-font-size;

                    @include mobile {
                          padding: 10px;
                          border-radius: 3px;
                    }
                }
    
                button{
                    cursor: pointer;
                    font-weight: 600;
                    font-size: $big-font-size;
                    background-color: $primary-background;
                    color: $primary-color;
                    box-shadow: $primary-shadow;
                    transition: 200ms all ease-in-out;
                    padding: $button-padding;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    border: $button-border;
                    margin-bottom: 20px;

                    @include mobile {
                        font-weight: 400;
                        font-size: $primary-font-size;
                  }
    
                    &:hover {
                        color: $focus-color;
                        box-shadow: none;
                    }
                }
            }
        }
    }

    .owl {
        position: absolute;
        bottom: 20px;
        right: 20px;
        transform: rotate(270deg) scale(0.6);
        overflow: hidden;

        @include mobile {
            bottom: 40px;
            right: 0;
            left: 0;
        }

        div {
            overflow: hidden;
        }
    }
}