.FineArt.darkMode {
    background: $standard-background;
    color: $primary-color;

    .componentContainer {
          .artShow {
            background-color: transparent;
            border: $primary-border;

            ::-webkit-scrollbar {
                background: $primary-background;
              }
              
              ::-webkit-scrollbar-thumb {
                background: $scroll-main-color
              }
        }
        .paintingsContainer {
            .paintingsCollection {
                .paintingContainer {
                    .painting {
                        background-color: $primary-background;
                    }
                }
            }
        }
    }
}

.FineArt.lightMode {
    background: $primary-color;
    color: $secondary-color;

    .componentContainer {
        .artShow {
            background-color: $light-box-background;
            border: $futured-border;
            color: $primary-color;

            ::-webkit-scrollbar {
                background: $focus-color;
            }
            
            ::-webkit-scrollbar-thumb {
                background: $standard-background;
            }

            .Navigation {
                color: $primary-color;
                background-color: $owl-focus-green;

                .scrollButton {
                    .mainScrollButton {
                        color: $primary-color;
                        background-color: $standard-background;
                    }
                }
            }
        }
        .paintingsContainer {
            .paintingsCollection {
                .paintingContainer {
                    .painting {
                        background-color: $light-box-background;
                    }
                }
            }
        }
    }
}

.FineArt {
    @include flex-direction(space-between, row);
    max-width: 100vw;
    height: 100%;

    .componentContainer {
        margin-left: 200px;
        width: calc(100vw - 200px);
        position: relative;
        height: 100%;

        @include mobile {
            margin-left: 0;
            width: 100vw;
        }

        h2 {
            position: fixed;
            top: 20px;
            right: 40px;
            z-index: 999;

            @include mobile {
                font-size: $primary-font-size;
            }
        }

        .artShow {
            width: $art-show-width;
            height: $art-show-height;
            margin: 0 auto;
            margin-top: 80px;
            overflow-y: hidden;
            border-radius: 10px;

            @include medium {
                width: $medium-art-show-width;
                height: $medium-art-show-height;
            }

            @include mobile {
            width: $mobile-art-show-width;
            height: $mobile-art-show-height;
            border-radius: 0;
            border: none;
            }

            ::-webkit-scrollbar {
                height: 30px;
                width: 25px;
                -webkit-border-radius: 0.5ex;

                @include medium {
                    height: 28px;
                    width: 20px;
                }
            
                @include mobile {
                  height: 20px;
                  width: 3px;
                }
              }
              
              ::-webkit-scrollbar-thumb {
                -webkit-border-radius: 0.5ex;
            
                @include mobile {
                  -webkit-border-radius: 0.3ex;
                }
              }
              
              ::-webkit-scrollbar-corner {
                background: $scroll-main-color;
              }                 
        }

        .moreArtButton {
            @include flex(center, center);
            position: relative;
            bottom: $basic-padding;
            font-size: $nav-font-size;
            margin-top: 100px;
            gap: 5px;
        }

        .paintingsContainer {
            @include flex-direction(center, column);
            gap: 20px;
            overflow: visible;
            position: relative;
            z-index: 996;
            margin-bottom: 80px;

            @include medium {
                gap: 10px;
            }

            .paintingsCollection {
                width: 100%;
                height: 100%;
                @include flex-direction(center, column);
                overflow: visible;
                position: relative;
                z-index: 997;

                h3 {
                    font-size: $nav-font-size;
                    margin: 60px;

                    @include medium {
                        font-size: $big-font-size;
                    }
                }
    
                .paintingContainer {
                    grid-template-columns: repeat(5, 1fr);
                    display: grid;
                    width: 80%;
                    gap: 40px;
                    height: 100%;
                    overflow: visible;
                    position: relative;
                    z-index: 998;

                    @include medium {
                        grid-template-columns: repeat(3, 1fr);
                        width: 90%;
                        gap: 30px;
                    }

                    @include mobile {
                        @include flex-direction(center, column);
                        width: 100%;
                        gap: 20px;
                    }
                    
                    .painting { 
                        width: 100%;
                        height: 100%;
                        border-radius: 5px;
                        transition: 200ms all ease-in-out;
                        position: relative;
                        overflow: visible;
                        z-index: 997;
                        @include flex-direction(center, column);

                        @include mobile {
                            width: 55%;
                            height: 400px;
                        }

                        h3 {
                            flex: 1;
                            font-size: $primary-font-size;
                            @include flex(flex-end, center);
                            text-align: center;
                            padding: 0;
                            margin: 20px 15px;

                            @include mobile {
                                font-size: $mobile-font-size;
                            }
                        }

                        .imageContainer {
                            flex: 5;
                            width: 100%;
                            max-height: 300px;
                            position: relative;
                            overflow: visible;

                            @include mobile {
                                max-width: 80%;
                                height: 500px;
                                margin-top: 30px;
                            }

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                                transition: 200ms all ease-in-out;
                                z-index: 998;
                            }

                            &:hover {
                                object-fit: contain;
                                z-index: 1000;
                                border: 5px solid black;
                                background-color: black;
                            }
                        }
                    }
    
                }
            }
        }
    }
}