.ArtExhibition.lightMode {
  background-color: rgb(248, 244, 224);
  .mainSection {
    background: linear-gradient(09deg, rgba(0, 0, 0, 0) 15.2%, rgba(0, 0, 0, 0) 18%,  
    $light-box-background 20.4%, $owl-focus-green 78%, $owl-focus-green 84.2%, rgba(0, 0, 0, 0) 88.2%,);
  }

  .navSection {
    background-color: $mode-color;
    .Navigation {
   
      h3 {
        background-color: $mode-color;
        color: $secondary-color;
      }
      .scrollButton {
        background-color: $mode-color;
      }
    }
  }
}

.ArtExhibition.darkMode {
  .mainSection {
    background: linear-gradient(09deg, rgba(0, 0, 0, 0) 15.2%, rgba(0, 0, 0, 0) 18%,  
    rgb(20, 20, 20) 20.4%, rgb(20, 20, 20) 78%, rgba(0, 0, 0, 0) 84.2%, rgba(0, 0, 0, 0) 88.2%,);
  }
}

.ArtExhibition {
    @include flex-direction(center, column);
    width: $art-show-width;
    height: $art-show-height;

    @include medium {
      width: $medium-art-show-width;
      height: $medium-art-show-height;
    }

    @include mobile {
      width: $mobile-art-show-width;
      height: $mobile-art-show-height;
    }

    .mainSection {
      flex: 3;
      width: $art-show-width;
      @include flex-direction(flex-start, row);
      overflow-x: scroll;
      overflow-y: hidden;

      @include medium {
        width: $medium-art-show-width;
      }
  
      @include mobile {
        width: $mobile-art-show-width;
      }

      div {
        @include flex(center, center);
        text-align: center;
        min-width: 100%;
        height: 100%;
        overflow: hidden;
  
        .MainPage {
          @include flex-direction(center, column);
          width: 100%;
          height: 100%;
          margin-top: 40px;
  
          h1 {
            flex: 1;
            @include flex(center, center);

            @include medium {
              font-size: $big-font-size;
            }
  
            @include mobile {
              font-size: $primary-font-size;
              margin-top: 0;
            }
          }
  
          h4 {
            flex: 2;
            @include flex(center, center);
            max-width: 80%;
            font-weight: 400;

            @include medium {
              max-width: 85%;
              font-size: $primary-font-size;
            }
  
            @include mobile {
              max-width: 90%;
              font-size: $small-mobile-font-size;
              font-weight: 300;
              flex: 3;
            }
          }
  
          @keyframes gradientAnimation {
            0% {
                background-position: 0% 50%;
              }
              50% {
                background-position: 100% 50%;
              }
              100% {
                background-position: 0% 50%;
              }
          }
  
          .animationContainer {
            flex: 3;
            @include flex(center,center);
            overflow: hidden;
            width: 100%;
            height: 100%;
            margin-bottom: 20px;

            @include medium {
              flex: 2;
            }
    
            .MainAnimation {
                width: 120px;
                height: 120px;
                animation: gradientAnimation 5s infinite;
                background-image: url(../assets/media/backgroundAnimation.jpg);
            }
          }
        }
      }
      
    }

    .navSection {
      flex: 1;
      @include flex(center, center);
      width: $art-show-width;
      overflow: hidden;

      @include medium {
        width: $medium-art-show-width;
        height: $medium-art-show-height;
      }
  
      @include mobile {
        width: $mobile-art-show-width;
        height: $mobile-art-show-height;
      }

      .Navigation {
        @include flex-direction(center, column);
        width: 100%;
        height: 100%;
        margin-bottom: 20px;

        h3 {
          flex: 1;
          @include flex(flex-end, center);
          width: 100%;
          height: 100%;

          @include medium {
            font-size: $primary-font-size;
            font-weight: 400;
          }

          @include mobile {
            font-size: $mobile-font-size;
          }
        }

        .scrollButton {
          flex: 2;
          @include flex(center, center);
          width: 100%;
          height: 100%;
          overflow: hidden;
          position: relative;

          .mainScrollButton {
            width: 120px;
            height: 50px;
            background-color: transparent;
            border-radius: 100px;
            border: $border-button;
            transition: 200ms all ease-in-out;
            overflow: hidden;
            color: $scroll-main-color;

            @include medium {
              width: 100px;
              height: 40px;
              @include flex(center, center);
            }

            @include mobile {
              width: 100px;
              height: 30px;
            }

            &:hover {
                border: $hover-border-button;
            }

            div {
                width: 120px;
                height: 50px;
                overflow: hidden;

                @include medium {
                  transform: scale(0.8);
                }

                @include mobile {
                  width: 100px;
                  height: 30px;
                }
            }
        }
      }
    }
  }
}