.Hero.darkMode {
    background: $hero-background;

    .futuredContainer {
        .recentProjects{
            background-color: $primary-background;

            h2 {
                @include mobile {
                    font-size: $primary-font-size;
                }
            }
        }
    }

    .messageContainer {
        a {
            overflow: hidden;
            background-color: $primary-background;
            div {
                overflow: hidden;

                h2 {
                    @include mobile {
                        font-size: $primary-font-size;
                    }
                }
            }
        }

        .contact {
            background-color: $light-box-background;
            border: $futured-border;
            transition: 200ms all ease-in-out;
            
                h2 {
                    color: $primary-color;
                }

            &:hover {
                background-color: $standard-background;
            }
        }

        .mainInfo {
            overflow: hidden;
            background: radial-gradient(
                circle at 50% -10%,  
                $light-box-background 0%, 
                $owl-green 8.9%, 
                $owl-focus-green 15.9%, 
                $focus-color 28.4%, 
                $primary-background 30%
            );
            transform: 200ms all ease;

            &:hover {
                background-color: $primary-background;
            }

            div {
                overflow: hidden;
                font-size: $primary-font-size;
            }
        }
    }
}

.Hero.lightMode {
    background: $light-hero-background;

    .futuredContainer {
        color: $primary-color;
        .recentProjects{
            background-color: $primary-color;

            a {
                color: $secondary-color;

                &:hover {
                    color: $light-box-background;
                }
            } 
        }
    }

    .messageContainer {
        a {
            background-color: $primary-color;
            color: $light-box-background;

            &:hover {
                color: $secondary-color;
            }
        }

        .mainInfo {
            background: radial-gradient(
                circle at 50% -10%,  
                $light-box-background 0%, 
                $owl-green 8.9%, 
                $owl-focus-green 15.9%, 
                $focus-color 28.4%, 
                $primary-color 30%
            );
            position: relative;
            transform: 200ms all ease;

            &:hover {
                background-color: $standard-background;
            }

            @include mobile {
                h2 {
                    position: relative;
                    top: 10px;
                }
            }



            span {
                font-weight: 600;
            }
        }

        .contact {
            background-color: $light-box-background;
            border: $futured-border;
            transition: 200ms all ease-in-out;
            
                h2 {
                    color: $primary-color;
                }

            &:hover {
                background-color: $standard-background;
            }
        }
    }
}

.Hero {
    height: 90%;
    width: calc(100vw - 200px);
    position: fixed;
    bottom: 0;
    @include flex-direction(center, row);

    @include medium {
        font-size: $primary-font-size;
        letter-spacing: 1px;
    }

    @include mobile {
        width: 100vw;
        height: 75%;
        padding-bottom: 20px;
    }

    .futuredContainer {
        flex: 1;
        height: 100%;
        padding: $basic-padding-right;
        @include flex-direction(flex-start, column);
        gap: 20px;

        @include medium {
            gap: 15px;
            padding: $mobile-padding;
        }

        @include mobile {
            gap: 10px;
            padding: 10px 5px 10px 10px;
        }

        .futuredProjects {
            flex: 2;
            width: 100%;
            height: 100%;
        }

        .recentProjects {
            flex: 1;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            border-top-left-radius: 100px;
            border-bottom-left-radius: 100px;
            text-align: center;
            box-shadow: $primary-shadow;
            transition: 200ms all ease-in-out;  
            width: 100%;
            height: 100%;
            position: relative;
            transition: 200ms all ease-in-out;

            &:hover {
                width: 80%;
            }

            a {
                width: 100%;
                height: 100%;
                @include flex-direction(flex-start, row);
                gap: 20%;
                position: relative;
                overflow: hidden;

                @include medium {
                    gap: 5%;
                }

                div {
                    overflow: hidden;
                }
            }

            @include mobile {
                border-radius: 5px;
                font-size: $mobile-font-size;
                padding: $mobile-padding;
                transition: 200ms all ease-in-out;

                &:hover {
                    width: 100%;
                    height: 90%;
                }

                a {
                    @include flex-direction(center, column);
                    gap: 0;
                    position: relative;
                    overflow: hidden;
                 
                    div {
                        flex: 3;
                        position: fixed;
                        @include flex(center, center);
                        transform: rotate(270deg) scale(0.6);
                        bottom: 70px;
                        overflow: hidden;
                    }

                    h2 {
                        flex: 2;
                        @include flex(center, center);
                        position: fixed;
                        bottom: 60px;
                        width: 20%;
                    }
                }
            }
            
            &:hover {
                color: $focus-color;
                box-shadow: none;
            }
        }
    }

    .messageContainer {
        flex: 1;
        height: 100%;
        padding: $basic-padding-right;
        @include flex-direction(flex-start, column);
        gap: 20px;
        overflow: hidden;

        @include medium {
            gap: 15px;
            padding: 20px 20px 20px 10px;
        }

        @include mobile {
            gap: 10px;
            padding: 10px 10px 10px 5px;
        }

        a {
            flex: 1;
            border-radius: 10px;
            text-align: center;
            box-shadow: $primary-shadow;
            transition: 200ms all ease-in-out;
            @include flex-direction(center, column);
            gap: 20px;
            width: 100%;
            padding: $basic-padding;

            @include medium {
                gap: 15px;
            }

            @include mobile {
                padding: $mobile-padding;
                font-size: $mobile-font-size;
                position: relative;

                span {
                    display: none;
                }
            }

            @include mobile {
                border-radius: 5px;
                font-size: $primary-font-size;
                padding: $mobile-padding;
            }
    
            &:hover {
                color: $focus-color;
                box-shadow: none;
            }
        }

        .mainInfo {
            flex: 4;
            @include flex-direction(flex-end, column);

            h2 {
                padding-bottom: 20px;

                @include medium {
                    padding-bottom: 50px;
                }
            }

            @include ipad {
                font-size: $primary-font-size;               
            }

            @include medium {
                span {
                    display: none;
                }
            }

            @include mobile {
                flex: 3;
                position: relative;
                overflow: hidden;                
            }
        }
    }
}